import React, { useState } from "react";
import { InnerImageZoom } from "react-inner-image-zoom";
import styled from "styled-components";
import Carousel from "../Carousel";
import Button from "../Button";
import { AiOutlineRotateRight } from "react-icons/ai";
import { AiOutlineFullscreen } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { css } from "styled-components";

const ZoomImageWrap = styled.div`
  ${(props) =>
    props.proofType === "dm" &&
    !props.isPopup &&
    css`
      position: relative;
    `}
  figure {
    background-image: linear-gradient(#ebebeb 2px, transparent 2px),
      linear-gradient(90deg, #ebebeb 2px, transparent 2px),
      linear-gradient(#ebebeb 1px, transparent 1px),
      linear-gradient(90deg, #ebebeb 1px, #fff 1px);
    background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
    background-position: -4px -10px, 0px -4px, 0px 0px, 0px 0px;
    height: 90vh;
    position: relative;
    display: block;
    > div {
      margin: auto;
      position: absolute;
      inset: 0;
      padding: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      .iiz__img {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .flip-btn {
    position: absolute;
    left: 1em;
    top: 1em;
  }
  .new-tab-btn {
    position: absolute;
    left: 4.8em;
    top: 1em;
  }
  .flipPosition1 {
    > div {
      max-width: 85vh;
    }
    .iiz__img,
    .iiz__zoom-img {
      transform: rotate(90deg);
    }
  }
  .flipPosition2 {
    .iiz__img,
    .iiz__zoom-img {
      transform: rotate(180deg);
    }
  }
  .flipPosition3 {
    > div {
      max-width: 85vh;
    }
    .iiz__img,
    .iiz__zoom-img {
      transform: rotate(270deg);
    }
  }
  .modal-carousel {
    figure {
      height: 62vh;
    }
    .flipPosition1 > div,
    .flipPosition3 > div {
      max-width: 60vh;
    }
  }
  .carousel-status {
    color: #333;
    text-shadow: none;
    font-size: 1em;
    right: 1em;
    top: 0.5em;
    padding: 0;
  }
  .carousel .control-dots {
    right: 1em;
    bottom: 1em;
  }
`;

const VariantCarousel = ({ proofs, proofType, isPopup = false }) => {
  const { t } = useTranslation("common");
  const [zoomedIn, setZoomedIn] = useState(false);
  const [flipPosition, setFlipPosition] = useState(0);
  const handleFlip = () => setFlipPosition((prev) => (prev < 3 ? prev + 1 : 0));
  const handleOpenTab = (url) => window.open(url);

  return (
    <ZoomImageWrap proofType={proofType} isPopup={isPopup}>
      <Carousel
        showThumbs={false}
        className={`${isPopup ? "modal-carousel" : ""} ${
          zoomedIn ? "zoomed-in" : ""
        } ${proofType === "edm" ? "edm" : ""}`}
        hideControls={zoomedIn}
        statusFormatter={(curr, total) => `${curr} ${t("ui.out-of")} ${total}`}
        showArrows={false}
        renderIndicator={(onClick, isSelected, index) => (
          <li
            className={`dot indicator ${isSelected ? "selected" : ""}`}
            value={0}
            role="button"
            tabIndex={0}
            aria-label={`slide item ${index}`}
            onClick={onClick}
          >
            {index + 1}
          </li>
        )}
      >
        {proofs.map((doc) =>
          doc.urls.map((url, index) => (
            <>
              <InnerImageZoom
                key={url}
                src={url}
                alt={doc.id + index}
                hideHint
                className={`flipPosition${flipPosition} proof-img`}
                afterZoomIn={() => setZoomedIn(true)}
                afterZoomOut={() => setZoomedIn(false)}
                zoomScale={0.75}
              />
              {!zoomedIn && proofType === "dm" ? (
                <>
                  <Button
                    secondary
                    square
                    onClick={handleFlip}
                    className="flip-btn"
                    title={t("ui.Rotate")}
                  >
                    <AiOutlineRotateRight />
                  </Button>
                  <Button
                    secondary
                    square
                    onClick={() => handleOpenTab(url)}
                    className="new-tab-btn"
                    title={t("ui.View-in-new-tab")}
                  >
                    <AiOutlineFullscreen />
                  </Button>
                </>
              ) : null}
            </>
          ))
        )}
      </Carousel>
    </ZoomImageWrap>
  );
};

export default VariantCarousel;
