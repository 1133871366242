import React from "react";
import styled from "styled-components";

const SEdmPreview = styled.div`
  background: #e4e4e4;
  text-align: center;
  padding: 1.2em;
  margin: 0 -1.2em -1.2em;
  iframe {
    width: 620px;
    max-width: 100%;
    margin: 0 auto;
    background: white;
    outline: none;
    border: 1px solid #ddd;
    display: block;
    min-height: 70vh;
  }
`;

const EdmPreview = ({ url, docId }) => {
  return (
    <SEdmPreview>
      <iframe src={url} title={`id: ${docId}`} />
    </SEdmPreview>
  );
};

export default EdmPreview;
