import React, { useState } from "react";
import Card from "../Card";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import { Row, Col } from "styled-bootstrap-grid";
import Button from "../Button";
import AddressBox from "./AddressBox";
import useContacts from "../../utils/useContacts";
import ContactPersonForm from "./ContactPersonForm";
import callContact from "../../utils/callContact";
import Dialog from "../Dialog";

import { useToast } from "@zendeskgarden/react-notifications";
import Loader from "../Loader";
import ButtonWrap from "../ButtonWrap";
import { useSWRConfig } from "swr";
import {
  IoBriefcaseSharp,
  IoHelpSharp,
  IoManSharp,
  IoWomanSharp,
} from "react-icons/io5";
import styled from "styled-components";
import getIconFromString from "../../utils/getIconFromString";
import opacify from "polished/lib/color/opacify";
import { FaBirthdayCake, FaUsers } from "react-icons/fa";
import { MONTHS, VOLVO } from "../../constants";
import useContactTypes from "../../utils/useContactTypes";
import Error from "../Error";
import formatDoB from "../../utils/formatDoB";
import config from "../../config";
import Notification from "../Notification";

const monthNames = MONTHS;

const SContactPersonBlock = styled(Card)`
  .gender-icon {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0.5em;
    font-size: 2em;
    color: ${(props) => opacify(-0.6, props.theme.accentColor)};
  }
`;

const ContactPersonsBlock = ({ customerId }) => {
  const { t } = useTranslation("common");
  const { data: persons, isLoading, isError, cacheKey } = useContacts();
  const textBlock =
    config?.clientCode === VOLVO
      ? t("my-account.Contact-description-Volvo")
      : t("my-account.Contact-description");
  const {
    data: contactTypesData,
    isLoading: contactTypesIsLoading,
    isError: contactTypesIsError,
  } = useContactTypes();
  const contactTypes =
    !!contactTypesData && !!contactTypesData.length
      ? contactTypesData.map((item) => ({
          value: item,
          label: item,
        }))
      : [];

  const { mutate } = useSWRConfig();

  // Confirm deletion dialog
  const [itemEditedId, setItemEditedId] = useState("");
  const [dialogId, setDialogId] = useState("");
  const { addToast } = useToast();

  const openDialog = (id) => {
    setDialogId(id);
    setItemEditedId(id);
  };
  const cancelDelete = () => {
    setDialogId("");
    setItemEditedId("");
  };

  const handleDelete = (id) => {
    callContact(
      id,
      "DELETE",
      {},
      () => {
        setDialogId("");
        mutate(cacheKey);
      },
      (error) => {
        setDialogId("");
        setItemEditedId("");
        addToast(
          ({ close }) => (
            <Notification
              title={t("ui.Something-went-wrong")}
              text={t("ui.Failed-to-delete-supplier")}
              errorText={
                !!error.title
                  ? error.title
                  : typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      }
    );
  };

  if (isLoading || contactTypesIsLoading) {
    return (
      <Card title={t("my-account.Contact-persons")} shadow icon={<FaUsers />}>
        <Loader />
      </Card>
    );
  }
  if (contactTypesIsError || isError) {
    return (
      <Card title={t("my-account.Contact-persons")} shadow icon={<FaUsers />}>
        <Error
          errorObj={
            contactTypesIsError ? contactTypesIsError : isError ? isError : null
          }
        />
      </Card>
    );
  }

  return (
    <SContactPersonBlock
      title={t("my-account.Contact-persons")}
      shadow
      icon={<FaUsers />}
    >
      <p>{textBlock}</p>
      <div className="row-wrap padding-top">
        <Row>
          {persons.map((item) => (
            <Col md={12} key={item.id}>
              <AddressBox
                isLoading={itemEditedId === item.id}
                className="fadeInDown"
                style={{ marginBottom: "1.2em", paddingLeft: "4.2em" }}
              >
                <div>
                  <h3>
                    {/* gender */}
                    {item.gender === "unknown" && (
                      <IoHelpSharp className="gender-icon" />
                    )}
                    {item.gender === "male" && (
                      <IoManSharp className="gender-icon" />
                    )}
                    {item.gender === "female" && (
                      <IoWomanSharp className="gender-icon" />
                    )}
                    {/* name */}
                    {item.prefix} {item.firstName} {item.lastName}
                  </h3>

                  {/* role */}
                  {!!item.roles.length ? (
                    <p>
                      <span className="list-label">
                        <span className="list-icon">
                          <IoBriefcaseSharp />
                        </span>
                        {t("my-account.Role")}{" "}
                      </span>
                      {item.roles.map((role, index) => (
                        <span key={role}>
                          <span>{role}</span>
                          {index < item.roles.length - 1 && ", "}{" "}
                        </span>
                      ))}
                    </p>
                  ) : null}

                  {/* Date of birth */}
                  {!!item.dateOfBirth ? (
                    <p>
                      <span className="list-label">
                        <span className="list-icon">
                          <FaBirthdayCake />
                        </span>
                        {t("my-account.Date-of-birth")}{" "}
                      </span>
                      {formatDoB(item.dateOfBirth).day}{" "}
                      {t(
                        `month.${monthNames[formatDoB(item.dateOfBirth).month]}`
                      )}{" "}
                      {formatDoB(item.dateOfBirth).year}
                    </p>
                  ) : null}

                  {/* Contact details */}
                  {!!item.contactDetails.length ? (
                    <>
                      <h4 style={{ marginTop: "1em" }}>
                        {t("my-account.Contact-details")}
                      </h4>
                      {item.contactDetails.map((item, index) => (
                        <p key={index}>
                          <span className="list-label">
                            <span className="list-icon">
                              {getIconFromString(item.type, icons.contact)}
                            </span>
                            {t(`my-account.${item.type}`)}
                          </span>{" "}
                          {item.value}
                        </p>
                      ))}
                    </>
                  ) : null}
                </div>
                <ButtonWrap>
                  <ContactPersonForm
                    formData={{ ...item }}
                    onSuccess={() => mutate(cacheKey)}
                    customerId={customerId}
                    contactTypes={contactTypes}
                  />
                  <Button link small onClick={() => openDialog(item.id)}>
                    {icons.trash}
                    <span>{t("my-account.Delete")}</span>
                  </Button>
                </ButtonWrap>
                {itemEditedId === item.id && (
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      right: 0,
                      top: 0,
                      bottom: 0,
                    }}
                  >
                    <Loader />
                  </div>
                )}
                {dialogId === item.id && (
                  <Dialog
                    title={t("my-account.Delete-contact")}
                    text={t("my-account.Would-you-like-to-delete-this-contact")}
                    name={item.firstName + " " + item.lastName}
                    onConfirm={() => handleDelete(item.id)}
                    onCancel={cancelDelete}
                  />
                )}
              </AddressBox>
            </Col>
          ))}
        </Row>
      </div>
      <div className="text-right">
        <ContactPersonForm
          onSuccess={() => mutate(cacheKey)}
          customerId={customerId}
          contactTypes={contactTypes}
        />
      </div>
    </SContactPersonBlock>
  );
};

export default ContactPersonsBlock;
