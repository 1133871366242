import useSWR from "swr";
import fetcherWithHeaders from "./fetcherWithHeaders";
import apiConfig from "../apiConfig";

const { order } = apiConfig;

const useOrders = ({
  shouldFetch = () => true,
  pageSize = 10,
  page = 1,
  extraParams = "",
}) => {
  const customerId = localStorage.getItem("customerId");
  const cacheKey = `${order}/orders?pageSize=${pageSize}&page=${page}&customerId=${customerId}${
    extraParams ? "&" + extraParams : ""
  }`;
  const { data, error } = useSWR(
    () => (shouldFetch() ? { url: cacheKey } : null),
    fetcherWithHeaders,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
    }
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    cacheKey: cacheKey,
  };
};

export default useOrders;
