import React, { useEffect, useState } from "react";
import Card from "../Card";
import { useTranslation } from "react-i18next";
import icons from "../../icons";
import Button from "../Button";
import AddressBox from "./AddressBox";
import { Textarea } from "@zendeskgarden/react-forms";
import ButtonWrap from "../ButtonWrap";
import { useToast } from "@zendeskgarden/react-notifications";
import config from "../../config";
import { VOLVO } from "../../constants";
import useMpProperties from "../../utils/useMpProperties";
import callMpProperties from "../../utils/callMpProperties";
import Notification from "../Notification";

const AddressBlock = ({ mpId, addressBlock = "", refreshMp }) => {
  const { t } = useTranslation("common");
  const [formOpen, setFormOpen] = useState(false);
  const { addToast } = useToast();
  const [shouldFetch, setShouldFetch] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [addressBlockText, setAddressBlockText] = useState(
    !!addressBlock && addressBlock !== "null" ? addressBlock : ""
  );

  const {
    data: mpProperties,
    isLoading,
    error,
    mutate,
  } = useMpProperties({ mpId, shouldFetch: () => shouldFetch });

  useEffect(() => {
    setShouldFetch(false);
  }, [mpProperties]);

  const textBlock =
    config?.clientCode === VOLVO
      ? t("my-account.Address-block-description-Volvo")
      : t("my-account.Address-block-description");

  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      setFormOpen(false);
    }
    if (e.key === "Enter" && e.ctrlKey) {
      handleUpdate();
    }
  };

  const handleUpdate = () => {
    setFormOpen(false);
    setIsBusy(true);
    callMpProperties({
      mpId: mpId,
      data: {
        ...mpProperties,
        addressBlock: addressBlockText.length > 0 ? addressBlockText : "",
      },
      onSuccess: () => {
        refreshMp();
        mutate();
        setShouldFetch(true);
        setIsBusy(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("my-account.Address-block-updated")}
              type="success"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
      onError: (error) => {
        setIsBusy(false);
        addToast(
          ({ close }) => (
            <Notification
              title={t("error.Something-went-wrong")}
              text={t("error.Failed-to-update-address")}
              errorText={
                !!error.title
                  ? error.title
                  : typeof error === "string" && !!error
                  ? error
                  : t("error.Unexpected-error-occurred")
              }
              type="error"
              handleClose={close}
            />
          ),
          { placement: "bottom" }
        );
      },
    });
  };

  return (
    <Card title={t("my-account.Address-block")} shadow icon={icons.newspaper}>
      <p className="margin-bottom">{textBlock}</p>
      {!formOpen ? (
        <AddressBox>
          {addressBlockText.length > 0 && addressBlockText.indexOf("\n") ? (
            <div
              style={{
                padding: "0.714286em 0.857143em",
                lineHeight: "1.28571",
                fontSize: "14px",
                border: "1px solid transparent",
              }}
              onClick={() => setFormOpen(true)}
            >
              {addressBlockText.split("\n").map((item, i) => (
                <div key={i}>{item}</div>
              ))}
            </div>
          ) : (
            addressBlockText
          )}
          <div
            style={{
              marginTop: "13px",
              minHeight: "40px",
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              padding: "0 1em",
            }}
          >
            <Button link onClick={() => setFormOpen(true)}>
              {icons.edit}
              <span>{t("my-account.Edit")}</span>
            </Button>
          </div>
        </AddressBox>
      ) : (
        <AddressBox>
          <Textarea
            defaultValue={addressBlockText}
            rows={10}
            onChange={(e) => setAddressBlockText(e.target.value)}
            onKeyDown={handleKeyDown}
            maxRows={10}
            style={{ minHeight: "6em" }}
            autoFocus
          />
          <ButtonWrap>
            <Button link onClick={() => setFormOpen(false)}>
              {icons.close}
              <span>{t("ui.Cancel")} </span>
            </Button>
            <Button
              primary
              onClick={handleUpdate}
              disabled={isLoading || !!error || isBusy}
            >
              {icons.check}
              <span>{t("my-account.Save")}</span>
            </Button>
          </ButtonWrap>
        </AddressBox>
      )}
    </Card>
  );
};

export default AddressBlock;
